<template>
  <div class="flex flex-column">
    <div class="flex card-title-wrapper">
      <div class="card-title">
        <slot name="title"></slot>
        <div v-if="isTopcard" class="card-toggle" @click="$emit('toggleTopCard')">
          <span v-if="isExpanded" class="material-icons-round grey">remove</span>
          <span v-else class="material-icons-round grey">add</span>
        </div>
      </div>
      <div class="title-content">
        <slot name="content"></slot>
      </div>
    </div>
    <div class="filters">
      <slot name="filters"></slot>
    </div>
  </div>
</template>
<script>
import {computed, toRef} from "vue";

export default {
  props: {
      topcard: {
          type: Boolean,
          default: false
      },
      expanded: {
          type: Boolean,
          default: true
      }
  },
  setup(props) {
    const isTopcard = computed(() => props.topcard)
    const isExpanded = toRef(props, 'expanded')

    return {
      isTopcard,
      isExpanded
    }
  }
}
</script>
<style scoped lang="scss">
.card-title-wrapper {
  justify-content: space-between;
  align-items: baseline;
}

.card-title {
  display: flex;
  padding: 30px 30px 0px 30px;
  padding-left: 0px;
  align-items: baseline;
}

.card-title::before {
  content: '';
  height: 20px;
  width: 10px;
  border-radius: 0px 50px 50px 0px;
  background-color: var(--clarity-blue);
  margin-right: 20px;
}

.title-content {
  display: flex;
  position: relative;
  align-items: flex-end;
  padding-right: 30px;
}

.filters {
  padding: 0px 30px 0px 30px;
}

.card-toggle {
  display: none;
  @include mob {
    display: flex;
    position: absolute;
    right: 4rem;
  }
}
</style>
