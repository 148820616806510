<template>
    <div>
        <div class="flex flex-column">
            <h3 class="mb-3">MORTGAGE CALCULATOR</h3>
            <div class="flex align-items-center justify-content-between">
                <div class="mr-5">
                    <div class="card-body">Use this tool to do a basic calculation that will tell you how much your repayments will be and how long it would take you to pay off a mortgage dependent on loan amounts, interest rate and how much you can afford to pay, based on the information you have inputted.</div>
                </div>
            </div>
        </div>

        <spacer-h />

        <div class="flex">
            <div class="flex-1 mr-3">
                <div class="flex mb-3" style="min-height:39px">
                    <div class="flex align-items-center justify-content-start">
                        <h3 style="margin-top:6px">MORTGAGE DETAILS</h3>
                    </div>
                </div>
                <div class="round-wrapper">
                    <div class="flex align-items-stretch">
                        <div class="flex flex-grow-1 align-items-center justify-content-start m-2 mr-4">
                            <h5 class="dark-grey">Property purchase price:</h5>
                        </div>
                        <div class="flex-none flex align-items-center justify-content-center m-2 mt-5 mb-5 input-wrapper">
                            <InputNumber @focus="$event.target.select()" v-model="propertyPurchasePrice" mode="currency" :currency="userCurrencyValue" :min="0" :maxFractionDigits="2" class="calc-input" style="width: 200px;" />
                          </div>
                    </div>
                    <div class="flex align-items-stretch">
                        <div class="flex flex-grow-1 align-items-center justify-content-start m-2 mr-4">
                            <h5 class="dark-grey">Amount of any fees added to the loan:</h5>
                        </div>
                        <div class="flex-none flex align-items-center justify-content-center m-2 mt-5 mb-5 input-wrapper">
                            <InputNumber @focus="$event.target.select()" v-model="mortgageFees" mode="currency" :currency="userCurrencyValue" :min="0" :maxFractionDigits="2" class="calc-input" style="width: 200px;" />
                          </div>
                    </div>
                    <div class="flex align-items-stretch">
                        <div class="flex flex-grow-1 align-items-center justify-content-start m-2 mr-4">
                            <h5 class="dark-grey">Less deposit paid:</h5>
                        </div>
                        <div class="flex-none flex align-items-center justify-content-center m-2 mt-5 mb-5 input-wrapper">
                            <InputNumber @focus="$event.target.select()" v-model="mortgageDeposit" mode="currency" :currency="userCurrencyValue" :min="0" :maxFractionDigits="2" class="calc-input" style="width: 200px;" />
                          </div>
                    </div>
                    <div class="flex align-items-stretch">
                        <div class="flex flex-grow-1 align-items-center justify-content-start m-2 mr-4">
                            <h5 class="dark-grey">Loan amount:</h5>
                        </div>
                        <div class="flex-none flex align-items-center justify-content-center m-2 mt-5 mb-5 input-wrapper">
                            <InputNumber v-model="mortgageLoanAmount" mode="currency" :currency="userCurrencyValue" :min="0" :maxFractionDigits="2" class="calc-input" style="width: 200px;" />
                          </div>
                    </div>
                    <div class="flex align-items-stretch">
                        <div class="flex flex-grow-1 align-items-center justify-content-start m-2 mr-4">
                            <div>
                                <h5 class="dark-grey">Mortgage Term:</h5>
                            </div>
                        </div>
                        <div class="flex-none flex align-items-center justify-content-center m-2 mt-5 mb-5 input-wrapper">
                            <InputNumber v-model="mortgageTerm" mode="decimal" showButtons suffix=" years" :min="1" class="calc-input" inputClass="investmentCalculatorInputformat" incrementButtonClass="investmentCalculatorInputBtnUp" decrementButtonClass="investmentCalculatorInputBtnDown" />
                        </div>
                    </div>
                    <div class="flex align-items-stretch">
                        <div class="flex flex-grow-1 align-items-center justify-content-start m-2 mr-4">
                            <div>
                                <h5 class="dark-grey">Interest rate (APR):</h5>
                            </div>
                        </div>
                        <div class="flex-none flex align-items-center justify-content-center m-2 mt-5 mb-5 input-wrapper">
                            <InputNumber @focus="$event.target.select()" v-model="interestRate" mode="decimal" showButtons suffix="%" :min="0" :minFractionDigits="2" :maxFractionDigits="2" :step="0.25" class="calc-input" inputClass="investmentCalculatorInputformat" incrementButtonClass="investmentCalculatorInputBtnUp" decrementButtonClass="investmentCalculatorInputBtnDown" />
                        </div>
                    </div>
                </div>
            </div>
            <div class="flex-1 mr-3">
                <div class="flex mb-3" style="min-height:39px">
                    <div class="flex align-items-center justify-content-start">
                        <h3 style="margin-top:6px">REPAYMENT FIGURES</h3>
                    </div>
                </div>
                <div class="round-wrapper">
                    <div class="flex align-items-stretch">
                        <div class="flex flex-column flex-grow-1 align-items-center justify-content-start m-2 mr-4">
                            <h5 class="dark-grey">Repayment Mortgage:</h5>
                            <h2 class="blue"><currency-value :value="repaymentPayments" :decimal-places="2" /><span class="monthLabel"> per month</span></h2>
                        </div>
                        <div class="flex flex-column flex-grow-1 align-items-center justify-content-start m-2 mr-4">
                            <h5 class="dark-grey">Interest Only:</h5>
                            <h2 class="blue"><currency-value :value="interestOnlyPayments" :decimal-places="2" /><span class="monthLabel"> per month</span></h2>
                        </div>
                    </div>
                    <div class="rateWarningBox">
                        <h3 class="orange"><img src="../../assets/icons/warning-icon.png" style="vertical-align: top;"> WHAT HAPPENS IF MORTGAGE RATES INCREASE?</h3>
                        <p>If interest rates rise your monthly payments could increase as shown in the table below (showing increases of 1% to 4%).</p>
                    </div>
                    <div class="mt-6">
                        <DataTable :value="tablechangingRates" responsiveLayout="scroll" class="rd-table">
                            <Column field="tableInterestRate" header="Interest Rate">
                                <template #body="slotProps">
                                    {{ slotProps.data.tableInterestRate }}% (+{{ slotProps.data.tableInterestRateIncrese }}%)
                                </template>
                            </Column>
                            <Column field="tableRepayment" header="Repayment Mortgage">
                                <template #body="slotProps">
                                    <currency-value :value="slotProps.data.tableRepayment" :decimal-places="2" style="font-size: 14px"/>
                                </template>
                            </Column>
                            <Column field="tableInterestOnly" header="Interest Only">
                                <template #body="slotProps">
                                    <currency-value :value="slotProps.data.tableInterestOnly" :decimal-places="2" style="font-size: 14px"/>
                                </template>
                            </Column>
                        </DataTable>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import SpacerH from '@/components/common/layout/SpacerH.vue';
    import {computed, ref} from 'vue';
    import {useStore} from "vuex";
    import CurrencyValue from "../common/CurrencyValue";

    export default {
        name: "RepaymentMortgageCalculator",
        setup() {
            const store = useStore();

            const propertyPurchasePrice = ref(0);
            const mortgageTerm = ref(store.state.calculators.mortgageDefaultTerm);
            const interestRate = ref(store.state.calculators.mortgageInterestRate);
            const mortgageFees = ref(0);
            const mortgageDeposit = ref(0);
            const userCurrencyValue = ref(store.getters.currencySelected);

            const mortgageLoanAmount = computed( () => {
                return propertyPurchasePrice.value - mortgageDeposit.value + mortgageFees.value;
            });

            const repaymentPayments = computed( () => {
                // P = (Pv*R) / [1 - (1 + R)^(-n)]
                const R = (interestRate.value / 100) / 12;
                const Pv = mortgageLoanAmount.value;
                const n = mortgageTerm.value * 12;
                const returnValue = (Pv*R) / [1 - (1+R)**(-n)];
                return returnValue;
            });

            const interestOnlyPayments = computed( () => {
                const rate = interestRate.value / 100;
                return mortgageLoanAmount.value * (rate / 12);
            });

            const changingRatesCalc = computed( () => {
                const baseR = interestRate.value;
                const rateOne = (baseR+1) / 100;
                const rateTwo = (baseR+2) / 100;
                const rateThree = (baseR+3) / 100;
                const rateFour = (baseR+4) / 100;
                const Pv = mortgageLoanAmount.value;
                const n = mortgageTerm.value * 12;

                const plusOne = {"tableInterestRateIncrese":1,"tableInterestRate":baseR+1,"tableRepayment":(Pv*(rateOne/12))/[1-(1+(rateOne/12))**(-n)],"tableInterestOnly":mortgageLoanAmount.value * (rateOne / 12)};
                const plusTwo = {"tableInterestRateIncrese":2,"tableInterestRate":baseR+2,"tableRepayment":(Pv*(rateTwo/12))/[1-(1+(rateTwo/12))**(-n)],"tableInterestOnly":mortgageLoanAmount.value * (rateTwo / 12)};
                const plusThree = {"tableInterestRateIncrese":3,"tableInterestRate":baseR+3,"tableRepayment":(Pv*(rateThree/12))/[1-(1+(rateThree/12))**(-n)],"tableInterestOnly":mortgageLoanAmount.value * (rateThree / 12)};
                const plusFour = {"tableInterestRateIncrese":4,"tableInterestRate":baseR+4,"tableRepayment":(Pv*(rateFour/12))/[1-(1+(rateFour/12))**(-n)],"tableInterestOnly":mortgageLoanAmount.value * (rateFour / 12)};

                let tabledata = {
                    data: [
                        plusOne,plusTwo,plusThree,plusFour
                    ]
                };
                return tabledata;
            });

            const tablechangingRates = computed( () => {
                return changingRatesCalc.value.data;
            });

            return {
                propertyPurchasePrice,
                mortgageTerm,
                interestRate,
                mortgageFees,
                mortgageDeposit,
                mortgageLoanAmount,
                repaymentPayments,
                interestOnlyPayments,
                tablechangingRates,
                changingRatesCalc,
                userCurrencyValue
            }
        },
        components: {
            SpacerH,
            CurrencyValue
        }
    }
</script>

<style scoped>
    .round-wrapper {
        min-height: 715px;
        background: var(--clarity-pure-white) 0% 0% no-repeat padding-box;
        border: 1px solid var(--clarity-light-grey);
        border-radius: 27px;
        opacity: 1;
        padding: 30px;
    }
    .input-wrapper {
        width: 200px;
    }
    .monthLabel {
        font-size: 14px;
        color: var(--clarity-dark-grey);
        text-transform: lowercase;
    }
    .rateWarningBox {
        width: 100%;
        height: auto;
        padding: 40px 10px;
        margin-top: 30px;
        border: 1px solid var(--clarity-snow-grey);
        box-shadow: 0px 5px 20px #0000000D;
        border-radius: 27px;
    }
    .rateWarningBox p {
        padding-left: 35px;
        margin-bottom: 0px;
    }
</style>
