<template>
    <content-card-full>
        <template #title>
            <card-title>
                <template #title><h2 class="title">MORTGAGE PAYMENT CALCULATOR</h2></template>
                <template #content>
                      <span @click="$router.back()" style="cursor: pointer;">
                        <span class="material-icons-round" style="vertical-align: middle;">chevron_left</span>Back to Calculators
                      </span>
                </template>
              </card-title>
        </template>
        <template #content-full>
            <div class="flex flex-column">
                <h3 class="mb-3">WELCOME TO THE MORTGAGE PAYMENT CALCULATOR</h3>
                <div class="flex align-items-center justify-content-between">
                    <div class="mr-5">
                        <div class="card-body">Use the mortgage calculator to determine your potential monthly repayments on a mortgage. The calculator will also project what effect any interest rate changes may have on your repayments. Our research library provides research notes with further information about mortgages.</div>
                    </div>
                </div>
            </div>

            <spacer-h />

            <TabView ref="mortgagetab" :activeIndex="active">
                <TabPanel>
                    <template #header>
                        <h3>MORTGAGE CALCULATOR</h3>
                    </template>
                    <RepaymentMortgageCalculator />
                </TabPanel>
            </TabView>

            <spacer-h/>
            <div class="flex flex-column">
                <h3 class="mb-3">REFERENCE</h3>
                <h4 class="my-2 blue">DISCLAIMER</h4>
                <p class="small-text mb-3">This calculator is to be considered as a guide only and makes assumptions which need to be referred to. Specifically, any assumed growth rates and investment returns may not be achieved. Applicable tax rates and relevant legislation are correct as at February 2023 but may be subject to change. It does not represent personalised advice.</p>
                <h4 class="mb-3 blue">ASSUMPTIONS</h4>
                <ul>
                    <li>Calculations assume that all premiums are made at the beginning of the payment period.</li>
                    <li>Interest is accrued per payment period and then applied annually. Annual premiums will receive a full years growth in the year of investment and this will be applied to the balance at the end of the year. Monthly/Weekly premiums will accrue interest from the date of investment so the first payment will receive a years growth, the second payment 11/12 and so on.</li>
                </ul>
                <h4 class="mb-3 blue">OTHER RESOURCES</h4>
                <div id="research-note-links" class="flex flex-wrap">
                    <div class="flex calc-note align-items-start"><a :href="clarityURL+'//sites/default/files/research/notes/types_mortgage_interest.pdf'" target="_blank"><img style="vertical-align:middle;" src="../../assets/icons/pdf-icon.svg">Types of Mortgage Interest</a></div>
                    <div class="flex calc-note align-items-start"><a :href="clarityURL+'//sites/default/files/research/notes/types_mortgage_repayment.pdf'" target="_blank"><img class="ml-3" style="vertical-align:middle;" src="../../assets/icons/pdf-icon.svg">Types of Mortgage Repayment</a></div>
                </div>
            </div>
        </template>
    </content-card-full>
</template>

<script>
import CardTitle from '@/components/common/CardTitle.vue'
import ContentCardFull from '@/components/common/ContentCardFull.vue'
import SpacerH from '@/components/common/layout/SpacerH.vue'
import RepaymentMortgageCalculator from './RepaymentMortgageCalculator.vue'

export default {
    name: "MortgageCalculator",
    setup() {
        const clarityURL = process.env.VUE_APP_CLARITYURL;

        return {
            active: 0,
            clarityURL
        }
    },
    components: {
        ContentCardFull,
        CardTitle,
        SpacerH,
        RepaymentMortgageCalculator
    }
}
</script>

<style scoped>
    #research-note-links a {
    color: var(--clarity-dark-grey);
  }
  .p-tabview :deep(.p-tabview-nav) {
    background: unset;
    border: none;
  }
  .p-tabview :deep(.p-tabview-nav-container) {
    margin: 0px 30px;
  }
  .p-tabview :deep(.p-tabview-panels) {
    padding: unset;
    background: var(--clarity-snow-grey);
  }
  .p-tabview :deep(.p-tabview-nav li .p-tabview-nav-link) {
    background: var(--clarity-snow-grey);
    display: flex;
    justify-content: center;
    align-items: flex-end;
    height: 75px;
    min-width: 280px;
    color: var(--clarity-dark-grey);
    padding-right: 45px;
    padding-left: 45px;
  }
  .p-tabview :deep(.p-tabview-nav li.p-highlight .p-tabview-nav-link) {
    background: var(--clarity-pure-white);
    color: var(--clarity-blue);
    border: 1px solid var(--clarity-light-grey);
    box-shadow: 0px 5px 20px #0000000D;
    border-radius: 27px 27px 0px 0px;
  }
  .p-tabview :deep(.p-tabview-ink-bar) {
    background-color: var(--clarity-blue);
    height: 5px;
    border-radius: 4px 4px 0px 0px;
    padding-right: 45px;
    padding-left: 45px;
  }

  .p-tabview :deep(.p-tabview-panels) {
    /* UI Properties */
    background: var(--clarity-pure-white) 0% 0% no-repeat padding-box;
    border: 1px solid var(--clarity-light-grey);
    background: #FFFFFF 0% 0% no-repeat padding-box;
    border: 1px solid #DCE1E6;
    border-radius: 27px;
    opacity: 1;
    padding: 30px;
  }
    .calc-note {
        margin-right: 15px;
        margin-bottom: 15px;
    }
</style>
